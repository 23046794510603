// <--- API --->
import { SchoolsApi } from 'api';

// <--- Third-Party Library --->
import { useQuery } from 'react-query';

// <--- Utilities --->
import { useAxiosPrivate } from 'utilities';

export const Footer = () => {
    const package_json = require('../../../../../package.json');
    const version = package_json.version;
    const axiosPrivate = useAxiosPrivate()

    // <--- useQuery --->
    const getAkunSekolah = useQuery(
        ['kesiswaan-atribut-akun-sekolah'],
        () => SchoolsApi.getAccount(axiosPrivate)
    )

    return (
        <footer className='border-t-2'>
            <div className="h-[60px] container mx-auto flex items-center">
                <label className='w-full text-center text-xs font-medium'>© 2022 {getAkunSekolah?.data?.name ?? '~'} - {version}</label>
            </div>
        </footer>
    )
}