import { getDashYMD } from "utilities";

export const formPelanggaranInitialValues = (data = {}) => ({
  violationPublicId: data?.violationPublicId ?? '',
  violationName: data?.violationName ?? undefined,
  description: data?.description ?? '',
  activityDate: data?.activityDate ?? getDashYMD(new Date()),
  isActive: true,
  studentPublicId: data?.studentPublicId ?? '',
  studentNis: data?.studentNis ?? '',
  studentName: data?.studentName ?? '',
  studentHasViolationPublicId: data?.studentHasViolationPublicId ?? undefined,
  classroomPublicId: ''
})

export const formPrestasiInitialValues = () => ({
  description: '',
  status: ''
})