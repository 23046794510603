import { ClassroomsApi, TeachersApi } from "api"
import { DataStatus, EditButton, Input, LabelDanger, LabelSuccess, ListLayout, Modal, Select, ViewButton } from "components"
import { Formik } from "formik"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { errorFetching, getDashDMY, mappingDataGender, paginationHandler, successFetching, useAuth, useAxiosPrivate } from "utilities"
import { DetailSection, FormSection } from "./__KesiswaanComponents__"
import { formInitialValues, formSubmitValueMapper, formValidationSchema } from "./__KesiswaanUtilities__"
import KesiswaanApi from "./__KesiswaanApi__"

export const Kesiswaan = () => {
  const { auth } = useAuth()
  const permissions = auth.permissions
  const axiosPrivate = useAxiosPrivate()
  
  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    sortBy: 'student_name',
    isActive: undefined
  })
  const [isActive, setIsActive] = useState('none')
  const [modal, setModal] = useState({
    show: false,
    type: 'create',
    errorText: '',
    error: false,
    data: {}
  })
  const [classroomPublicId, setClassroomPublicId] = useState('')

  // <--- Table's column --->
  const columns = [
    {
      name: 'No.',
      selector: (row, index) => ((getSiswa.data?.pageable?.pageNumber - 1) * getSiswa.data?.pageable?.pageSize) + index + 1,
      width: "70px" 
    },
    {
      name: 'NIS',
      selector: row => row?.nis,
      width: '150px',
      wrap: true
    },
    {
      name: 'Nama Siswa',
      selector: row => row?.name,
      wrap: true,
      minWidth: '300px'
    },
    {
      name: 'Jenis Kelamin',
      selector: row => mappingDataGender(row?.gender),
      width: '120px',
      wrap: true
    },
    {
      name: 'Tanggal Lahir',
      selector: row => row?.dateOfBirth ? getDashDMY(row?.dateOfBirth) : '-',
      width: '130px',
      wrap: true
    },
    {
      name: 'Status',
      cell: data => data?.isActive ? <LabelSuccess text='Aktif' /> : <LabelDanger text='Tidak Aktif' />,
      width: '120px'
    },
    {
      name: 'Aksi',
      button: true,
      cell: (data) => <>
        <div className="flex flex-row">
          {permissions.includes('STUDENT_R') && <ViewButton icon noText onClick={() => onReadButtonClickHandler(data)} className='mr-1' />}
          {permissions.includes('STUDENT_U') && <EditButton icon noText onClick={() => onUpdateButtonClickHandler(data)} className='mr-1' />}
        </div>
      </>,
      width: '100px'
    }
  ]

  // <--- useQuery --->
  const getDetailGuru = useQuery(
    ['detail-guru', 'siswa'], 
    () => TeachersApi.getDetailTeacher(axiosPrivate)
  )

  const getKelas = useQuery(
    ['kesiswaan-atribut-kelas', 'siswa'],
    () => ClassroomsApi.getClassrooms(axiosPrivate, {gradePublicId: getDetailGuru?.data?.gradeGuide?.publicId}),
    {enabled: Boolean(!getDetailGuru.isFetching && !getDetailGuru.isError && permissions.find(val => val === 'GURU_BK'))}
  )

  const getSiswa = useQuery(
    ['siswa-list', 'siswa', pagination, classroomPublicId], 
    () => KesiswaanApi.getList(axiosPrivate, permissions.find(val => val === 'WALI_KELAS') ? getDetailGuru?.data?.classroomGuide?.publicId : classroomPublicId, paginationHandler(pagination)),
    {enabled: permissions.find(val => val === 'WALI_KELAS') ? Boolean(getDetailGuru?.data?.publicId) : Boolean(classroomPublicId !== '')}
  )

  // <--- Functions --->
  const handlePageChange = page => setPagination({...pagination, page: page})

  const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

  const searchDataHandler = (e) => {
    let time

    clearTimeout(time)

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: 'all',
      })
    }, 1000)
  }

  const activeDataHandler = (e) => {
    if(e.target.name !== 'all'){
      setPagination({
        ...pagination,
        isActive: e.target.name
      })
      setIsActive(e.target.name)
    } else{
      setPagination({
        ...pagination,
        isActive: undefined
      })
      setIsActive('none')
    }
  }
    
  const onHideModalHandler = () => setModal({ show: false, type: 'create', errorText: '', error: false, data: {} })

  const onReadButtonClickHandler = data => setModal({ show: true, type: 'read', errorText: '', error: false, data: data})

  const onUpdateButtonClickHandler = data => setModal({ show: true, type: 'update', errorText: '', error: false, data: data })
  
  // <--- useMutation --->
  const updateSiswa = useMutation(({data, publicId}) => KesiswaanApi.update(axiosPrivate, data, publicId), {
    onSuccess: () => {
			getSiswa.refetch()
		}
  })

  return (
    <>
      {permissions.find(val => val === 'GURU_BK') &&
        (getDetailGuru.isFetching || getDetailGuru.isError) ? 
          <DataStatus 
            loading={getDetailGuru.isLoading} 
            loadingText='Memuat data...' 
            text='Gagal memuat data guru'
          /> :
            <>
              {permissions.find(val => val === 'GURU_BK') && 
                <div className="flex flex-row mb-4">
                  <Input 
                    label='Tingkatan Kelas'
                    value={getDetailGuru?.data?.gradeGuide?.name}
                    wrapperClassName='mr-2 w-28'
                    withSelect={true}
                    disabled
                  />
                  <Select 
                    label='Kelas'
                    placeholder='Pilih Kelas'
                    defaultValue={getKelas?.data?.find(item => item.value === classroomPublicId)}
                    onChange={val => setClassroomPublicId(val.value)}
                    options={getKelas?.data ?? []}
                    errorFetch={getKelas.isError}
                    errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
                    loading={getKelas.isFetching}
                    disable={Boolean(getDetailGuru.isLoading)}
                  />
                </div>
              }
              {permissions.find(val => val === 'GURU_BK') && classroomPublicId === '' ?
                <DataStatus text='Silahkan memilih kelas terlebih dahulu' /> :
                  // <--- Search input, Create Button, and Table ---> 
                  <ListLayout 
                    permissions={permissions}
                    data={getSiswa.data?.content}
                    columns={columns}
                    loading={Boolean(getDetailGuru.isFetching || getSiswa.isFetching)}
                    error={getSiswa.error?.response?.data?.errorMessage[0]}
                    pagination={true}
                    totalRows={getSiswa.data?.pageable?.totalElements}
                    handlePageChange={handlePageChange}
                    handlePerRowsChange={handlePerRowsChange}
                    onSearchChange={searchDataHandler}
                    activeStatusFilter
                    activeOnChange={activeDataHandler}
                    activeValues={isActive}
                  />
              }
            </>
      }

      {/* <--- MODAL READ ---> */}
      {modal.type === 'read' && modal.show &&
        <Modal
            id='modal-detil-siswa'
            header='Detil Siswa'
            size='large'
            type='read'
            onHide={onHideModalHandler}
            show={Boolean(modal.show && modal.type === 'read')}
        >
          <DetailSection data={modal} />
        </Modal>
      }
            
      {/* <--- MODAL UPDATE ---> */}
      {modal.type === 'update' && modal.show &&
        <Formik
          enableReinitialize
          initialValues={formInitialValues(modal?.data)}
          validationSchema={formValidationSchema()}
          onSubmit={(values, { resetForm }) => {
            const finalValues = formSubmitValueMapper(values)

            updateSiswa.mutateAsync({
              data: finalValues,
              publicId: finalValues.publicId
            }).then((res) => {
              resetForm()
              successFetching(res)
              onHideModalHandler()
            }).catch(err => {
              const errorMessage = errorFetching(err)
              setModal({
                ...modal,
                error: true,
                errorText: errorMessage
              })
              })
          }}
        >
          {(formik) => {
            const { handleSubmit } = formik

            return (
              <Modal
                id='modal-ubah-siswa'
                header='Ubah Siswa'
                size='small'
                type='update'
                onHide={onHideModalHandler}
                show={Boolean(modal.show && modal.type === 'update')}
                onSubmit={handleSubmit}
                isSubmitting={updateSiswa.isLoading}
              >
                <FormSection modal={modal} publicId={modal.data.publicId} />
              </Modal>
            )
          }}
        </Formik>
      }
    </>
  )
}
