const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class KesiswaanApi {
  async getList(axiosPrivate, classroomId, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/classrooms/${classroomId}/students/pageable`, { params })
    return fetch.data.payload
  }

  async detail(axiosPrivate, id) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/${id}`)
    return fetch.data.payload
  }

  update(axiosPrivate, data, id) {
    return axiosPrivate.put(`${url}/v1/students/${id}`, data)
  }
}

export default new KesiswaanApi()