import * as Yup from 'yup'

export const formValidationPelanggaranSchema = (action) => {
  if(action === 'create'){
    return Yup.object().shape({
      classroomPublicId: Yup.string()
        .required('Wajib dipilih')
        .nullable(),
      studentPublicId: Yup.string()
        .required('Wajib dipilih')
        .nullable(),
      violationPublicId: Yup.string()
        .required('Wajib dipilih')
        .nullable(),
      activityDate: Yup.date()
        .required('Tanggal Pelanggaran wajib diisi')
    })
  } else{
    return Yup.object().shape({
      violationPublicId: Yup.string()
        .required('Wajib dipilih')
        .nullable(),
      activityDate: Yup.date()
        .required('Tanggal Pelanggaran wajib diisi')
    })
  }
}

export const formValidationPrestasiSchema = () => {
  return Yup.object().shape({
    description: Yup.string()
      .required('Deskripsi wajib diisi'),
    status: Yup.string()
      .required('Wajib dipilih')
      .nullable()
  })
}