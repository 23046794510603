import { ClassroomsApi, GradeApi, SchoolsApi } from "api";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { errorFetching, getDashYMD, useAxiosPrivate } from "utilities";
import PresensiHarianApi from "./__PresensiHarianApi__";
import { ConvertFlagPresensiHarian } from "./__PresensiHarianComponents__";
import { AntdTable, DefaultButton, Input, Select } from "components";

export const PresensiHarian = () => {
  const axiosPrivate = useAxiosPrivate();
  const [filter, setFilter] = useState({
    gradePublicId: undefined,
    classroomPublicId: undefined,
    periodePublicId: undefined,
    date: getDashYMD(new Date()),
  });

  const getReport = useQuery(
    [
      "presensi-harian-siswa",
      "report",
      filter.classroomPublicId,
      filter.periodePublicId,
      filter.date,
    ],
    () =>
      PresensiHarianApi.getReport(
        axiosPrivate,
        filter.classroomPublicId,
        filter.periodePublicId,
        filter.date
      ),
    {
      enabled: Boolean(
        filter.classroomPublicId && filter.periodePublicId && filter.date
      ),
      onError: (err) => {
        errorFetching(err);
      },
    }
  );

  const getTahunAjaran = useQuery(
    ["presensi-harian-siswa", "dropdown", "tahun-ajaran"],
    () => SchoolsApi.getTahunAjaran(axiosPrivate, { direction: "DESC" }),
    {
      onSuccess: (res) =>
        setFilter({
          ...filter,
          periodePublicId: res?.find((val) => val?.isActive)?.publicId,
        }),
    }
  );

  const getTingkatanKelas = useQuery(
    ["presensi-harian-siswa", "dropdown", "tingkatan-kelas"],
    () =>
      GradeApi.getGrade(axiosPrivate, {
        isActive: true,
        sortBy: "name",
        direction: "ASC",
      })
  );

  const getKelas = useQuery(
    ["presensi-harian-siswa", "dropdown", "kelas", filter?.gradePublicId],
    () =>
      ClassroomsApi.getClassrooms(axiosPrivate, {
        isActive: true,
        sortBy: "name",
        gradePublicId: filter.gradePublicId,
      }),
    { enabled: Boolean(filter?.gradePublicId) }
  );

  const columns = [
    {
      title: "No",
      render: (_, __, index) => `${index + 1}.`,
      width: 60,
      align: "center",
    },
    {
      title: "NIS",
      render: (row, _, __) => row?.siswaNis,
      width: 100,
      align: "center",
    },
    {
      title: "Nama Siswa",
      render: (row, _, __) => <div className="text-left">{row?.siswaNama}</div>,
      width: 400,
      align: "center",
    },
  ];

  const dateColumns = () => {
    const tampArray = [];

    if (getReport?.data?.students) {
      const resDate = getReport?.data?.students?.[0].date;

      tampArray.push({
        title: "Status",
        width: 60,
        align: "center",
        className: `${
          resDate.isHoliday || resDate.isWeekend ? "bg-red-200" : ""
        }`,
        render: (row, _, __) =>
          row?.date?.attendance ? (
            <ConvertFlagPresensiHarian flag={row?.date?.attendance.flag} />
          ) : row?.date?.isHoliday || row?.date?.isWeekend ? (
            "L"
          ) : (
            "-"
          ),
      });
    }

    return tampArray;
  };

  const mergeColumns = columns.concat(dateColumns());

  const KeteranganComponent = () => {
    return (
      <div className="mt-5 w-full text-sm">
        <div className="underline font-semibold">Keterangan:</div>
        <div className="flex flex-row space-x-10">
          <div className="flex">
            <ConvertFlagPresensiHarian />
            <div className="mx-2">:</div> Tidak Presensi
          </div>
          <div className="flex">
            <ConvertFlagPresensiHarian flag="MASUK" />
            <div className="mx-2">:</div> Masuk
          </div>
          <div className="flex">
            <ConvertFlagPresensiHarian flag="TERLAMBAT" />
            <div className="mx-2">:</div> Terlambat
          </div>
          <div className="flex">
            <div className="w-10">
              <ConvertFlagPresensiHarian flag="IZIN_SAKIT" />
            </div>
            <div className="mx-2">:</div> Sakit
          </div>
          <div className="flex">
            <div className="w-10">
              <ConvertFlagPresensiHarian flag="IZIN" />
            </div>
            <div className="mx-2">:</div> Izin
          </div>
          <div className="flex">
            <div className="w-10">
              <ConvertFlagPresensiHarian flag="MASUK_KELUAR" />
            </div>
            <div className="mx-2">:</div> Izin Meninggalkan Sekolah
          </div>
          <div className="flex">
            <div className="w-10">
              <ConvertFlagPresensiHarian flag="IZIN_TERLAMBAT" />
            </div>
            <div className="mx-2">:</div> Izin Terlambat
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="sm:flex sm:flex-row sm:space-x-3 sm:space-y-0 space-y-3 mb-5">
        <div className="sm:flex-grow">
          <Select
            label="Tahun Ajaran"
            placeholder="Pilih Tahun Ajaran"
            defaultValue={getTahunAjaran?.data?.find(
              (item) => item.value === filter?.periodePublicId
            )}
            onChange={(val) =>
              setFilter({
                ...filter,
                periodePublicId: val.value,
              })
            }
            options={getTahunAjaran?.data ?? []}
            errorFetch={getTahunAjaran.isError}
            errorFetchText={
              getTahunAjaran?.error?.response?.data?.errorMessage[0]
            }
            loading={getTahunAjaran.isFetching}
          />
        </div>
        <div className="sm:flex-grow">
          <Input
            label="Tanggal"
            name="startDate"
            type="date"
            value={filter.date}
            onChange={(e) =>
              setFilter({
                ...filter,
                date: e.target.value,
              })
            }
            wrapperClassName="mr-1"
          />
        </div>
        <div className="sm:flex-grow">
          <Select
            label="Tingkatan Kelas"
            placeholder="Pilih Tingkatan Kelas"
            defaultValue={getTingkatanKelas?.data?.find(
              (item) => item.value === filter?.gradePublicId
            )}
            onChange={(val) =>
              setFilter({
                ...filter,
                gradePublicId: val.value,
                classroomPublicId: undefined,
              })
            }
            options={getTingkatanKelas?.data ?? []}
            errorFetch={getTingkatanKelas.isError}
            errorFetchText={
              getTingkatanKelas?.error?.response?.data?.errorMessage[0]
            }
            loading={getTingkatanKelas.isFetching}
          />
        </div>
        <div className="sm:flex-grow">
          <Select
            label="Kelas"
            placeholder="Pilih Kelas"
            defaultValue={getKelas?.data?.find(
              (item) => item.value === filter?.classroomPublicId
            )}
            onChange={(val) =>
              setFilter({
                ...filter,
                classroomPublicId: val.value,
              })
            }
            options={getKelas?.data ?? []}
            errorFetch={getKelas.isError}
            errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
            loading={getKelas.isFetching}
            disable={!Boolean(filter.gradePublicId)}
          />
        </div>
        <div className="grid content-end">
          <DefaultButton
            text="Reset Pencarian"
            onClick={() =>
              setFilter({
                ...filter,
                classroomPublicId: undefined,
                gradePublicId: undefined,
                periodePublicId: getTahunAjaran?.data?.find(
                  (val) => val?.isActive
                )?.publicId,
                date: getDashYMD(new Date()),
              })
            }
          />
        </div>
      </div>

      <AntdTable
        data={
          Boolean(
            filter?.classroomPublicId &&
              filter?.date &&
              filter?.gradePublicId &&
              filter?.periodePublicId
          )
            ? getReport?.data?.students
            : [] ?? []
        }
        columns={mergeColumns}
        pagination={false}
        size="small"
        scrollY={500}
        loading={getReport.isFetching}
      />
      <KeteranganComponent />
    </>
  );
};
