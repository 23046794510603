import { DropdownApi } from "api";
import { Input, RowDetailLayout, Select } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { useQuery } from "react-query";

export const FormVerifikasiAkun = () => {
  const { values, setValues, handleChange, errors, touched } =
    useFormikContext();

  const getEnumVerifikasi = useQuery(
    ["enum-status-verifikasi", "dropdown", "orang-tua"],
    () => DropdownApi.getEnumVerifikasi()
  );

  return (
    <>
      <RowDetailLayout label="Nama Orang Tua" text={values?.orangTuaNama} />
      <RowDetailLayout label="No.Handphone" text={values?.orangTuaNoHp} />
      <RowDetailLayout label="Kategori" text={values?.orangTuaKategoriNama} />
      <RowDetailLayout label="Nama Anak" text={values?.siswaNama} />
      <Select
        label="Status"
        placeholder="Pilih Status"
        name="enumStatus"
        defaultValue={getEnumVerifikasi?.data?.find(
          (item) => item.value === values.enumStatus
        )}
        onChange={(val) =>
          setValues({
            ...values,
            enumStatus: val.value,
            keterangan: val.value === "APPROVED" ? "disetujui" : "",
          })
        }
        options={getEnumVerifikasi?.data ?? []}
        loading={getEnumVerifikasi.isFetching}
        error={Boolean(errors.enumStatus && touched.enumStatus)}
        errorText={
          Boolean(errors.enumStatus && touched.enumStatus) &&
          errors.enumStatus
        }
      />
      {values.enumStatus === "REJECTED" && (
        <Input
          label="Alasan Ditolak"
          name="keterangan"
          type="text"
          value={values.keterangan ?? "-"}
          onChange={handleChange}
          error={Boolean(errors.keterangan && touched.keterangan)}
          errorText={Boolean(errors.keterangan && touched.keterangan) && errors.keterangan}
        />
      )}
    </>
  );
};
