export const formInitialValues = (data, username) => ({
  name: data?.name,
  nip: data?.nip ?? '', 
  username: username,
  gender: data?.gender ?? '',
  dateOfBirth: data?.dateOfBirth ?? '',
  email: data?.email ?? '',
  isActive: data?.isActive,
  teacherCategoryPublicId: data?.teacherCategory?.publicId,
  street: data?.address?.street ?? '',
  address: {
    desaId: data?.address?.desa?.id ?? '',
    kecamatanId: data?.address?.desa?.kecamatan?.id ?? '',
    kabupatenId: data?.address?.desa?.kecamatan?.kabupaten?.id ?? '',
    provinsiId: data?.address?.desa?.kecamatan?.kabupaten?.provinsi?.id ?? '',
    street: data?.address?.street ?? ''
  }
})