// Components
import {
  DataStatus,
  Input,
  Label,
  RadioButton,
  RadioButtonGroup,
  RadioButtonWrapper,
  Select,
  Tabs,
  TextArea,
} from "components";

// Form
import { useFormikContext } from "formik";

// Third-Party Library
import { useQuery } from "react-query";

// Utilities
import { useAxiosPrivate } from "utilities";
import { formInitialValues } from "../__KesiswaanUtilities__";

// API
import KesiswaanApi from "../__KesiswaanApi__";
import { DropdownApi, RegionalApi, SchoolsApi, UsersApi } from "api";

// React
import { useEffect, useState } from "react";

const TabInformasiUmum = ({ modal, userPublicId }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, errors, touched, handleChange } =
    useFormikContext();
  const params = {
    except: userPublicId,
  };

  // <--- useQuery --->
  const getAkunSekolah = useQuery(
    ["kesiswaan-atribut-akun-sekolah"],
    () => SchoolsApi.getAccount(axiosPrivate),
    {
      enabled: modal.show,
      onSuccess: (res) => {
        setValues({
          ...values,
          periode: res?.periode,
        });
      },
    }
  );

  const getGender = useQuery(
    ["kesiswaan-atribut-gender"],
    () => DropdownApi.getGender(),
    { enabled: modal.show }
  );

  return (
    <div className="space-y-3">
      <div className="sm:flex sm:flex-row space-y-3 sm:space-y-0">
        <Input
          label="Tahun Ajaran"
          value={
            getAkunSekolah.isFetching
              ? "Memuat data tahun ajaran..."
              : getAkunSekolah?.data?.periode
          }
          wrapperClassName="sm:flex-1 sm:mr-1"
          disabled
        />
        <Input
          label="Kelas"
          value={`${values?.grade} ${values?.classroom}`}
          wrapperClassName="sm:flex-1 sm:ml-1"
          disabled
        />
      </div>
      <Input
        label="Nama Siswa"
        name="name"
        type="text"
        value={values?.name}
        onChange={handleChange}
        error={Boolean(errors.name && touched.name)}
        errorText={Boolean(errors.name && touched.name) && errors.name}
      />
      <Input
        label="NIS (Nomor Induk Siswa)"
        name="nis"
        type="number"
        value={values?.nis}
        onChange={handleChange}
        error={Boolean(errors.nis && touched.nis)}
        errorText={Boolean(errors.nis && touched.nis) && errors.nis}
      />
      <Input
        label="Username"
        name="username"
        type="text"
        value={values?.username}
        disabled={true}
      />
      <Select
        label="Jenis Kelamin"
        placeholder="Pilih Jenis Kelamin"
        name="gender"
        defaultValue={getGender?.data?.find(
          (item) => item.value === values?.gender
        )}
        onChange={(val) =>
          setValues({
            ...values,
            gender: val.value,
          })
        }
        options={getGender?.data ?? []}
        error={Boolean(errors?.gender && touched?.gender)}
        errorText={Boolean(errors?.gender && touched?.gender) && errors?.gender}
        errorFetch={getGender.isError}
        errorFetchText={getGender?.error?.response?.data?.errorMessage[0]}
        loading={getGender.isFetching}
      />
      <Input
        label="Tanggal Lahir"
        name="dateOfBirth"
        type="date"
        value={values?.dateOfBirth}
        onChange={handleChange}
        error={Boolean(errors.dateOfBirth && touched.dateOfBirth)}
        errorText={
          Boolean(errors.dateOfBirth && touched.dateOfBirth) &&
          errors.dateOfBirth
        }
      />
      <Input 
        label="No. Hp"
        name="phoneNo"
        placeholder="Cth. 085123456789"
        value={values?.phoneNo}
        onChange={handleChange}
        error={Boolean(errors.phoneNo && touched.phoneNo)}
        errorText={Boolean(errors.phoneNo && touched.phoneNo) && errors.phoneNo}
      />
      <Input
        label="Email"
        name="email"
        type="email"
        placeholder="Cth. example@email.com"
        value={values?.email}
        onChange={handleChange}
        error={Boolean(errors.email && touched.email)}
        errorText={Boolean(errors.email && touched.email) && errors.email}
      />
      <RadioButtonWrapper label="Status">
        <RadioButtonGroup>
          <RadioButton
            checked={Boolean(values?.isActive)}
            label="Aktif"
            onChange={() => setValues({ ...values, isActive: true })}
          />
          <RadioButton
            checked={Boolean(!values?.isActive)}
            label="Tidak Aktif"
            onChange={() => setValues({ ...values, isActive: false })}
          />
        </RadioButtonGroup>
      </RadioButtonWrapper>
      {modal.error && <Label type="error" text={modal.errorText} />}
    </div>
  );
};

const TabAlamat = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, errors, touched, handleChange } =
    useFormikContext();

  // <--- useQuery --
  const getProvinsi = useQuery(
    ["kesiswaan-atribut-provinsi"],
    () => RegionalApi.getProvinsi(axiosPrivate),
    { enabled: modal.show }
  );

  const getKabupaten = useQuery(
    ["kesiswaan-atribut-kabupaten", values?.address?.provinsiId],
    () =>
      RegionalApi.getKabupaten(axiosPrivate, {
        provinsiId: values?.address?.provinsiId,
      }),
    { enabled: Boolean(values?.address?.provinsiId) }
  );

  const getKecamatan = useQuery(
    ["kesiswaan-atribut-kecamatan", values?.address?.kabupatenId],
    () =>
      RegionalApi.getKecamatan(axiosPrivate, {
        kabupatenId: values?.address?.kabupatenId,
      }),
    { enabled: Boolean(values?.address?.kabupatenId) }
  );

  const getDesa = useQuery(
    ["kesiswaan-atribut-desa", values?.address?.kecamatanId],
    () =>
      RegionalApi.getDesa(axiosPrivate, {
        kecamatanId: values?.address?.kecamatanId,
      }),
    { enabled: Boolean(values?.address?.kecamatanId) }
  );

  return (
    <div className="space-y-3">
      <Select
        label="Provinsi"
        placeholder="Pilih Provinsi"
        name="provinsiId"
        defaultValue={getProvinsi?.data?.find(
          (item) => item.value === values?.address?.provinsiId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: {
              ...values.address,
              provinsiId: val.value,
              kabupatenId: "",
              kecamatanId: "",
              desaId: "",
            },
          })
        }
        options={getProvinsi?.data ?? []}
        error={Boolean(
          errors?.address?.provinsiId && touched?.address?.provinsiId
        )}
        errorText={
          Boolean(
            errors?.address?.provinsiId && touched?.address?.provinsiId
          ) && errors?.address?.provinsiId
        }
        errorFetch={getProvinsi.isError}
        errorFetchText={getProvinsi?.error?.response?.data?.errorMessage[0]}
        loading={getProvinsi.isFetching}
      />
      <Select
        label="Kabupaten"
        placeholder="Pilih Kabupaten"
        name="kabupatenId"
        defaultValue={getKabupaten?.data?.find(
          (item) => item.value === values?.address?.kabupatenId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: {
              ...values.address,
              kabupatenId: val.value,
              kecamatanId: "",
              desaId: "",
            },
          })
        }
        options={getKabupaten?.data ?? []}
        error={Boolean(
          errors?.address?.kabupatenId && touched?.address?.kabupatenId
        )}
        errorText={
          Boolean(
            errors?.address?.kabupatenId && touched?.address?.kabupatenId
          ) && errors?.address?.kabupatenId
        }
        errorFetch={getKabupaten.isError}
        errorFetchText={getKabupaten?.error?.response?.data?.errorMessage[0]}
        loading={getKabupaten.isFetching}
        disable={!Boolean(values?.address?.provinsiId)}
      />
      <Select
        label="Kecamatan"
        placeholder="Pilih Kecamatan"
        name="kecamatanId"
        defaultValue={getKecamatan?.data?.find(
          (item) => item.value === values?.address?.kecamatanId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: {
              ...values.address,
              kecamatanId: val.value,
              desaId: "",
            },
          })
        }
        options={getKecamatan?.data ?? []}
        error={Boolean(
          errors?.address?.kecamatanId && touched?.address?.kecamatanId
        )}
        errorText={
          Boolean(
            errors?.address?.kecamatanId && touched?.address?.kecamatanId
          ) && errors?.address?.kecamatanId
        }
        errorFetch={getKecamatan.isError}
        errorFetchText={getKecamatan?.error?.response?.data?.errorMessage[0]}
        loading={getKecamatan.isFetching}
        disable={!Boolean(values?.address?.kabupatenId)}
      />
      <Select
        label="Desa"
        placeholder="Pilih Desa"
        name="desaId"
        defaultValue={getDesa?.data?.find(
          (item) => item.value === values?.address?.desaId
        )}
        onChange={(val) =>
          setValues({
            ...values,
            address: { ...values.address, desaId: val.value },
          })
        }
        options={getDesa?.data ?? []}
        error={Boolean(errors?.address?.desaId && touched?.address?.desaId)}
        errorText={
          Boolean(errors?.address?.desaId && touched?.address?.desaId) &&
          errors?.address?.desaId
        }
        errorFetch={getDesa.isError}
        errorFetchText={getDesa?.error?.response?.data?.errorMessage[0]}
        loading={getDesa.isFetching}
        disable={!Boolean(values?.address?.kecamatanId)}
      />
      <TextArea
        label="Alamat"
        name="street"
        value={values?.street}
        rows={4}
        onChange={handleChange}
        error={Boolean(errors.street && touched.street)}
        errorText={Boolean(errors.street && touched.street) && errors.street}
      />
    </div>
  );
};

const TabSection = ({ modal, userPublicId }) => {
  return (
    <Tabs
      data={[
        {
          title: "Informasi Umum",
          component: (
            <TabInformasiUmum modal={modal} userPublicId={userPublicId} />
          ),
        },
        {
          title: "Alamat",
          component: <TabAlamat modal={modal} />,
        },
      ]}
    />
  );
};

export const FormSection = ({ modal, publicId }) => {
  const axiosPrivate = useAxiosPrivate();
  const { setValues, resetForm } = useFormikContext();
  const [userPublicId, setUserPublicId] = useState("");

  useEffect(() => {
    if (modal.show && modal.type === "create") {
      resetForm();
    }
  }, [modal.show]);

  const getDetail = useQuery(
    ["kesiswaan-atribut-detail", publicId],
    () => KesiswaanApi.detail(axiosPrivate, publicId),
    {
      enabled: Boolean(modal.show && modal.type === "update"),
      onSuccess: (data) => {
        setValues(formInitialValues(data));
        setUserPublicId(data?.user?.publicId);
      },
    }
  );

  if (modal.type === "create") {
    return <TabSection modal={modal} />;
  } else {
    if (getDetail.isError || getDetail.isFetching) {
      return (
        <DataStatus
          loading={getDetail.isFetching}
          loadingText="Memuat data siswa..."
          text="Data siswa gagal dimuat"
        />
      );
    } else {
      return <TabSection modal={modal} userPublicId={userPublicId} />;
    }
  }
};
