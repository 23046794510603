const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class VerifikasiAkunApi {
  async getList(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v2/orang-tua-siswa/pageable`, {
      params,
    });
    return fetch.data.payload;
  }

  verifikasi(axiosPrivate, orangTuaPublicId, data) {
    return axiosPrivate.patch(
      `${url}/v2/orang-tua-siswa/${orangTuaPublicId}/verfikasi`,
      data
    );
  }
}

export default new VerifikasiAkunApi();
