import { useAuth } from "utilities"
import { ContentPelanggaran, ContentPrestasi } from "./__BerandaComponents__"

export const Beranda = () => {
    const { auth } = useAuth()
    const permissions = auth.permissions

    if(permissions.find(val => val === 'WALI_KELAS')){
        return <ContentPrestasi />
    } else if(permissions.find(val => val === 'GURU_BK')){
        return <ContentPelanggaran />
    } else{
        return (
            <div>Beranda</div>
        )
    }
}