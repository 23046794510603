const url = window._env_.REACT_APP_API_STUDENTSHIP_URL;

class PresensiHarianApi {
  async getReport(axiosPrivate, classroomPublicId, periodePublicId, date) {
    const fetch = await axiosPrivate.get(
      `${url}/v2/presensi/kelas/${classroomPublicId}/tahun-ajaran/${periodePublicId}/tanggal/${date}`
    );
    return fetch.data.payload;
  }
}

export default new PresensiHarianApi();
