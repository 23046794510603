import { mappingDataOptions } from "utilities"

const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class ViolationsApi {
  async getListPelanggaran(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/violations?isActive=true&direction=ASC&sortBy=name`, { params })
    return mappingDataOptions(fetch.data.payload)
  }
}

export default new ViolationsApi()