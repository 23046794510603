const dateConvert = (date) => {
    const year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    
    if(month < 10) month = '0' + month
    if(day < 10) day = '0' + day

    return {
        year: year,
        month: month,
        day: day
    }
}

export const getDashDMY = (date) => {
    try{
        const result = dateConvert(date)
        return `${result.day}-${result.month}-${result.year}`
    } catch(e){
        try{
            return date.split("-").reverse().join("-");
        } catch(er){
            return '-'
        }
    }
}

export const getDashDMYTime = (date) => {
    const dateSplit = date.substr(0, 10).split("-")
    const time = date.substr(11)
    return `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]} ${time}`
}

export const getDashYMD = (date) => {
    try{
        const result = dateConvert(date)
        return `${result.year}-${result.month}-${result.day}`
    } catch(e){
        try{
            return date.split("-").reverse().join("-");
        } catch(er){
            return '-'
        }
    }
}

export const getSlashDMY = (date) => {
    try{
        const result = dateConvert(date)
        return `${result.day}/${result.month}/${result.year}`
    } catch(e){
        try{
            return date.split("/").reverse().join("/");
        } catch(er){
            return '-'
        }
    }
}