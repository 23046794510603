import { AUTH_URL } from "../../helper/UrlHelper";
import { axiosPublic } from "../../services";
import { useAuth } from "./useAuth";

export const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    await axiosPublic
      .get(`${AUTH_URL}/v1/token/refresh`, {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("siswaku-guru-id")}`,
        },
      })
      .then((res) => {
        const accessToken = res.data.payload.accessToken;
        const refreshToken = res?.data?.payload?.refreshToken;
        const resPermissions = res?.data?.payload?.permissions;
        localStorage.setItem("siswaku-guru-id", refreshToken);
        setTimeout(() => {
          setAuth((prev) => {
            return {
              ...prev,
              status: "authenticated",
              permissions: resPermissions,
              accessToken,
            };
          });
          return res.data.payload.accessToken;
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          setAuth({ status: "unauthenticated" });
          localStorage.removeItem("siswaku-guru-id");
          localStorage.removeItem("siswaku-guru-link");
        }, 1000);
      });
  };

  return refresh;
};
