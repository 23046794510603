import { Spinner } from "components";
import { Button } from "../Button";

export const DefaultButton = ({
  type,
  onClick,
  disabled,
  loading,
  icon,
  customIcon,
  noText,
  text,
  className,
}) => {
  return (
    <Button
      type={type}
      className={`btn-default justify-center flex flex-row items-center p-2 ${
        noText ?? "px-5"
      } ${className}`}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      {loading ? (
        <>
          <Spinner />
          {noText ?? <span className="ml-2">Proses...</span>}
        </>
      ) : (
        <>
          {icon && customIcon}
          {noText ?? <span>{text}</span>}
        </>
      )}
    </Button>
  );
};
