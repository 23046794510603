class DropdownApi {
  async getGender() {
    const data = [
      { value: "P", label: "Perempuan" },
      { value: "L", label: "Laki-Laki" },
    ];
    return data;
  }

  async getStatusPrestasi() {
    const data = [
      { value: 2, label: "APPROVED" },
      { value: 3, label: "REVISION" },
      { value: 4, label: "REJECTED" },
    ];
    return data;
  }

  async getEnumVerifikasi() {
    return [
      { value: "APPROVED", label: "APPROVED" },
      { value: "REJECTED", label: "REJECTED" },
    ];
  }
}

export default new DropdownApi();
