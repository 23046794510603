export const formInitialValues = (data = {}) => ({
    publicId: data?.publicId ?? undefined,
    gradeId: data?.classroom?.grade?.publicId ?? undefined,
    grade: data?.classroom?.grade?.name ?? undefined,
    classroomId: data?.classroom?.publicId ?? undefined,
    classroom: data?.classroom?.name ?? undefined,
    name: data?.name ?? '',
    nis: data?.nis ?? '',
    username: data?.user?.username ?? '',
    gender: data?.gender ?? '',
    dateOfBirth: data?.dateOfBirth ?? '',
    email: data?.email ?? '',
    phoneNo: data?.phoneNo ?? '',
    isActive: data?.isActive ?? true,
    street: data?.address?.street ?? '',
    address: {
        desaId: data?.address?.desa?.id ?? '',
        kecamatanId: data?.address?.desa?.kecamatan?.id ?? '',
        kabupatenId: data?.address?.desa?.kecamatan?.kabupaten?.id ?? '',
        provinsiId: data?.address?.desa?.kecamatan?.kabupaten?.provinsi?.id ?? '',
        street: data?.address?.street ?? ''
    }
})