import { DropdownApi, TeachersApi } from "api"
import { Card, DataStatus, EditButton, ListLayout, Modal, RowDetailLayout, Select, TextArea } from "components"
import { Formik, useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { errorFetching, getDashDMY, getDashDMYTime, paginationHandler, statusConvertPrestasi, successFetching, useAuth, useAxiosPrivate } from "utilities"
import { formPrestasiInitialValues, formValidationPrestasiSchema } from "../__BerandaUtilities__"
import { useMediaQuery } from "react-responsive"
import BerandaApi from "../__BerandaApi__"

const InformasiKegiatanSection = ({ data, modal }) => {
  const styleValue = "text-sm text-gray-700 pl-3 w-full"
  const styleLabel = "w-40"
  const isMobile = useMediaQuery({ query: `(max-width: 769px)` })

  if(isMobile){
    return (
      <>
        <RowDetailLayout 
          label='Nama Siswa'
          text={modal?.data?.studentName}
        />
        <RowDetailLayout 
          label='NIS'
          text={modal?.data?.studentNis}
        />
        <RowDetailLayout 
          label='Nama Kegiatan'
          text={data?.name}
        />
        <RowDetailLayout 
          label='Partisipasi'
          text={data?.achievement?.achievementParticipation?.name}
        />
        <RowDetailLayout 
          label='Jenis Kegiatan'
          text={data?.achievement?.achievementLibrary?.name}
        />
        <RowDetailLayout 
          label='Regional'
          text={data?.achievement?.achievementRegional?.name}
        />
        <RowDetailLayout 
          label='Tanggal Kegiatan'
          text={data?.activityDate ? getDashDMY(data?.activityDate) : '-'}
        />
        <RowDetailLayout 
          label='Poin'
          text={data?.achievement?.point}
        />
        <RowDetailLayout 
          label='Keterangan'
          text={data?.description}
        />
      </>
    )
  } else{
    return (
      <div className="space-y-1">
        <div className="grid grid-cols-2 space-x-5">
          <div className="space-y-1">
            {/* LEFT SECTION */}
            <div className="flex flex-row">
              <small className={styleLabel}>Nama Siswa</small>
              <small>:</small>
              <strong className={styleValue}>{modal?.data?.studentName}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>NIS</small>
              <small>:</small>
              <strong className={styleValue}>{modal?.data?.studentNis}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>Nama Kegiatan</small>
              <small>:</small>
              <strong className={styleValue}>{data?.name}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>Partisipasi</small>
              <small>:</small>
              <strong className={styleValue}>{data?.achievement?.achievementParticipation?.name}</strong>
            </div>
          </div>
          <div className="space-y-1">
            {/* RIGHT SECTION */}
            <div className="flex flex-row">
              <small className={styleLabel}>Jenis Kegiatan</small>
              <small>:</small>
              <strong className={styleValue}>{data?.achievement?.achievementLibrary?.name}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>Regional</small>
              <small>:</small>
              <strong className={styleValue}>{data?.achievement?.achievementRegional?.name}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>Tanggal Kegiatan</small>
              <small>:</small>
              <strong className={styleValue}>{data?.activityDate ? getDashDMY(data?.activityDate) : '-'}</strong>
            </div>
            <div className="flex flex-row">
              <small className={styleLabel}>Poin</small>
              <small>:</small>
              <strong className={styleValue}>{data?.achievement?.point}</strong>
            </div>
          </div>
        </div>
        <RowDetailLayout 
          label='Keterangan'
          text={data?.description}
        />
      </div>
    )
  }
}

const LogKegiatanSection = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate()
  const { studentPublicId, publicId } = modal?.data

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 3,
    isActive: true,
    direction: 'DESC'
  })

  // <--- useQuery --->
  const getLogs = useQuery(
    ['logs-prestasi-siswa-atribut-guru', pagination],
    () => BerandaApi.getAchievementLogsPrestasiSiswa(axiosPrivate, studentPublicId, publicId, paginationHandler(pagination)),
    {enabled: Boolean(modal.show && modal.type === 'update')}
  )

  // <--- Columns --->
  const columns = [
    {
      name: 'No.',
      selector: (_, index) => index + 1,
      width: "70px" 
    },
    {
      name: 'Keterangan',
      selector: row => row?.description,
      wrap: true,
      minWidth: '250px'
    },
    {
      name: 'Status',
      selector: row => statusConvertPrestasi(row?.status),
      width: '125px'
    },
    {
      name: 'Tanggal Simpan',
      selector: row => getDashDMYTime(row?.createdAt),
      width: '160px',
      wrap: true
    }
  ]

  // <--- Functions --->
  const handlePageChange = page => setPagination({...pagination, page: page})

  const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

  return (
    // <div className="mt-4">
    //   <strong className="text-lg font-semibold">Log Kegiatan</strong>
      <ListLayout 
        data={getLogs.data?.content}
        columns={columns}
        loading={Boolean(getLogs.isFetching)}
        error={getLogs.error?.response?.data?.errorMessage[0]}
        pagination={true}
        totalRows={getLogs.data?.pageable?.totalElements}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        paginationRowsPerPageOptions={[3,5]}
        customLeftTopSection={() => <></>}
      />
    // </div>
  )
}

const FormStatusPrestasi = ({ modal }) => {
  const axiosPrivate = useAxiosPrivate()
  const { values, setValues, errors, touched, handleChange, resetForm } = useFormikContext()
  const { studentPublicId, publicId } = modal?.data

  useEffect(() => {
    if(modal.show && modal.type === 'update'){
      resetForm()
    }
  }, [modal.show])

  // useQuery
  const getDetail = useQuery(
    ['detail-prestasi-siswa-atribut-guru', studentPublicId, publicId],
    () => BerandaApi.getDetailPrestasiSiswa(axiosPrivate, studentPublicId, publicId),
    {enabled: Boolean(modal.show && modal.type === 'update')}
  )

  const getStatusPrestasi = useQuery(
    ['status-prestasi-atribut-guru', 'dropdown'],
    () => DropdownApi.getStatusPrestasi(),
    {enabled: Boolean(modal.show && modal.type === 'update')}
  )

  if(getDetail.isFetching || getDetail.isError){
    return (
      <DataStatus 
        loading={getDetail.isFetching}
        loadingText='Memuat data kegiatan siswa...'
        text='Data kegiatan siswa gagal dimuat'
      />
    )
  } else{
    return (
      <>
        <Card 
          title="Informasi Kegiatan"
          body={<InformasiKegiatanSection data={getDetail?.data} modal={modal} />}
        />
        <Card 
          title="Log Kegiatan"
          wrapperClassname="mt-3 mb-3"
          body={<LogKegiatanSection modal={modal} />}
        />
        <Select 
          label='Status'
          placeholder='Pilih Status'
          name='status'
          defaultValue={getStatusPrestasi?.data?.find(item => item.value === values?.status)}
          onChange={val => {
            if(values?.status === '' && val.value === 2){
              setValues({ ...values, status: val.value, description: 'APPROVED' })
            } else{
              if(values?.description === '' && val.value === 2){
                setValues({ ...values, status: val.value, description: 'APPROVED' })
              } else{
                if(values?.status === 2 && values?.description === 'APPROVED'){
                  setValues({ ...values, status: val.value, description: '' })
                } else{
                  setValues({ ...values, status: val.value})
                }
              }
            }
          }}
          options={getStatusPrestasi?.data ?? []}
          error={Boolean(errors?.status && touched?.status)}
          errorText={Boolean(errors?.status && touched?.status) && errors?.status}
          errorFetch={getStatusPrestasi.isError}
          errorFetchText={getStatusPrestasi?.error?.response?.data?.errorMessage[0]}
          loading={getStatusPrestasi.isFetching}
        />
        {(values?.status === 3 || values?.status === 4) && 
          <div className="mt-3">
            <TextArea 
              label='Keterangan'
              name='description'
              value={values.description}
              rows={3}
              onChange={handleChange}
              error={Boolean(errors.description && touched.description)}
              errorText={Boolean(errors.description && touched.description) && errors.description}
            />
          </div>
        }
      </>
    )
  }
}

export const ContentPrestasi = () => {
  const { auth } = useAuth()
  const permissions = auth.permissions
  const axiosPrivate = useAxiosPrivate()

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    isActive: true,
    classroomPublicId: undefined,
    status: 1,
    direction: 'DESC'
  })
  const [modal, setModal] = useState({
    show: false,
    type: 'update',
    errorText: '',
    error: false,
    data: {}
  })

  // <--- useQuery --->
  const getDetailGuru = useQuery(
    ['detail-guru'], 
    () => TeachersApi.getDetailTeacher(axiosPrivate).then(res => {
      setPagination({
        ...pagination,
        classroomPublicId: res?.classroomGuide?.publicId
      })
    })
  )

  const getPrestasi = useQuery(
    ['prestasi-siswa-list', pagination],
    () => BerandaApi.getListPrestasi(axiosPrivate, pagination).catch(() => { return [] }),
    {enabled: Boolean(!getDetailGuru.isFetching && !getDetailGuru.isError && pagination?.classroomPublicId !== undefined)}
  )

  // <--- Functions --->
  const handlePageChange = page => setPagination({...pagination, page: page})

  const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

  const onHideModalHandler = () => setModal({ show: false, type: 'create', errorText: '', error: false, data: {} })

  const onUpdateButtonClickHandler = data => setModal({ show: true, type: 'update', errorText: '', error: false, data: data })

  // <--- useMutation --->
  const updateStatusPrestasi = useMutation(({data, studentPublicId, studentHasAchievementPublicId}) => BerandaApi.updateStatusPrestasi(axiosPrivate, studentPublicId, studentHasAchievementPublicId, data), {
    onSuccess: () => {
      getPrestasi.refetch()
    }
  })

  // <--- Table's column --->
  const columns = [
    {
      name: 'No',
      selector: (row, index) => ((getPrestasi.data?.pageable?.pageNumber - 1) * getPrestasi.data?.pageable?.pageSize) + index + 1,
      width: "70px" 
    },
    {
      name: 'NIS',
      selector: row => row?.studentNis,
      width: '80px',
      wrap: true
    },
    {
      name: 'Nama Siswa',
      selector: row => row?.studentName,
      wrap: true,
      minWidth: '250px'
    },
    {
      name: 'Kegiatan',
      selector: row => row?.name,
      wrap: true,
      minWidth: '200px'
    },
    {
      name: 'Partisipasi',
      selector: row => row?.participationName,
      wrap: true,
      minWidth: '200px'
    },
    {
      name: 'Jenis Kegiatan',
      selector: row => row?.achievementName,
      wrap: true,
      minWidth: '150px'
    },
    {
      name: 'Regional',
      selector: row => row?.regional,
      wrap: true,
      minWidth: '150px'
    },
    {
      name: 'Tgl. Kegiatan',
      selector: row => row?.activityDate ? getDashDMY(row?.activityDate) : '-',
      width: '110px',
      wrap: true
    },
    {
      name: 'Aksi',
      button: true,
      cell: (data) => <>
        <div className="flex flex-row">
          {permissions.includes('ACHIEVEMENT_U') && <EditButton icon noText onClick={() => onUpdateButtonClickHandler(data)} className='mr-1' />}
        </div>
      </>,
      width: '80px'
    }
  ]

  if(getDetailGuru.isFetching || getDetailGuru.isError){
    return (
      <DataStatus 
        loading={getDetailGuru.isLoading} 
        loadingText='Memuat data...' 
        text='Gagal memuat data guru'
      /> 
    )
  } else{
    return (
      <>
        <small className="font-bold text-base">Daftar Kegiatan Siswa (Status: Pending)</small>
        {/* <--- Table ---> */}
        <ListLayout 
          permissions={permissions}
          data={getPrestasi.data?.content}
          columns={columns}
          loading={Boolean(getDetailGuru.isFetching || getPrestasi.isFetching)}
          error={getPrestasi.error?.response?.data?.errorMessage[0]}
          pagination={true}
          totalRows={getPrestasi.data?.pageable?.totalElements}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowsChange}
          customLeftTopSection={() => <></>}
        />
            
        {/* <--- MODAL UPDATE ---> */}
        {modal.type === 'update' && modal.show &&
          <Formik
            enableReinitialize
            initialValues={formPrestasiInitialValues()}
            validationSchema={formValidationPrestasiSchema()}
            onSubmit={(values, { resetForm }) => {
              console.log(values)
              let finalValues = {}
              if(values.status === 2){
                finalValues = {
                  status: values?.status
                }
              } else{
                finalValues = {...values}
              }

              updateStatusPrestasi.mutateAsync({
                data: finalValues,
                studentPublicId: modal?.data.studentPublicId,
                studentHasAchievementPublicId: modal?.data.publicId
              }).then((res) => {
                resetForm()
                successFetching(res)
                onHideModalHandler()
              }).catch(err => {
                const errorMessage = errorFetching(err)
                setModal({
                    ...modal,
                    error: true,
                    errorText: errorMessage
                })
              })
            }}
          >
            {(formik) => {
              const { handleSubmit } = formik

              return (
                <Modal
                  id='modal-ubah-status-prestasi-siswa'
                  header='Ubah Status Kegiatan Siswa'
                  size='large'
                  type='update'
                  onHide={onHideModalHandler}
                  show={Boolean(modal.show && modal.type === 'update')}
                  onSubmit={handleSubmit}
                  isSubmitting={updateStatusPrestasi.isLoading}
                >
                  <FormStatusPrestasi modal={modal} />
                </Modal>
              )
            }}
          </Formik>
        }
      </>
    )
  }
}
