import { ClassroomsApi, TeachersApi, ViolationsApi } from "api";
import {
  CreateButton,
  DataStatus,
  DeleteButon,
  EditButton,
  Input,
  Label,
  ListLayout,
  Modal,
  RowDetailLayout,
  Select,
  TextArea,
} from "components";
import { Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  errorFetching,
  getDashDMY,
  getDashYMD,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import BerandaApi from "../__BerandaApi__";
import {
  formPelanggaranInitialValues,
  formValidationPelanggaranSchema,
} from "../__BerandaUtilities__";

const FormPelanggaran = ({ modal, grade }) => {
  const axiosPrivate = useAxiosPrivate();
  const { values, setValues, errors, touched, handleChange, resetForm } =
    useFormikContext();

  useEffect(() => {
    if (modal.show && modal.type === "create") {
      resetForm();
    }
  }, [modal.show]);

  // <--- useQuery --->
  const getKelas = useQuery(
    ["tambah-pelanggaran-atribut-kelas"],
    () =>
      ClassroomsApi.getClassrooms(axiosPrivate, {
        gradePublicId: grade?.publicId,
      }),
    { enabled: modal.show }
  );

  const getSiswa = useQuery(
    ["list-siswa-berdasarkan-kelas", values.classroomPublicId],
    () =>
      ClassroomsApi.getStudentsByClassroom(
        axiosPrivate,
        values.classroomPublicId
      ),
    {
      enabled: Boolean(
        modal.type === "create" && values.classroomPublicId !== ""
      ),
    }
  );

  const getPelanggaran = useQuery(
    ["list-pelanggaran"],
    () => ViolationsApi.getListPelanggaran(axiosPrivate),
    { enabled: modal.show }
  );

  return (
    <div className="space-y-3">
      {modal.type === "create" ? (
        <div className="flex flex-row">
          <Input
            label="Tingkatan Kelas"
            value={grade?.name}
            wrapperClassName="flex-1 mr-1"
            disabled
          />
          <div className="flex-1 ml-1">
            <Select
              label="Kelas"
              placeholder="Pilih Kelas"
              name="classroomPublicId"
              defaultValue={getKelas?.data?.find(
                (item) => item.value === values?.classroomPublicId
              )}
              onChange={(val) =>
                setValues({ ...values, classroomPublicId: val.value })
              }
              options={getKelas?.data ?? []}
              error={Boolean(
                errors?.classroomPublicId && touched?.classroomPublicId
              )}
              errorText={
                Boolean(
                  errors?.classroomPublicId && touched?.classroomPublicId
                ) && errors?.classroomPublicId
              }
              errorFetch={getKelas.isError}
              errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
              loading={getKelas.isFetching}
            />
          </div>
        </div>
      ) : (
        <Input label="Kelas" value={modal?.data?.classroomName} disabled />
      )}
      {modal.type === "create" ? (
        <Select
          label="Siswa"
          placeholder="Pilih Siswa"
          name="studentPublicId"
          defaultValue={getSiswa?.data?.find(
            (item) => item.value === values?.studentPublicId
          )}
          onChange={(val) =>
            setValues({ ...values, studentPublicId: val.value })
          }
          options={getSiswa?.data ?? []}
          error={Boolean(errors?.studentPublicId && touched?.studentPublicId)}
          errorText={
            Boolean(errors?.studentPublicId && touched?.studentPublicId) &&
            errors?.studentPublicId
          }
          errorFetch={getSiswa.isError}
          errorFetchText={getSiswa?.error?.response?.data?.errorMessage[0]}
          loading={getSiswa.isFetching}
          disable={Boolean(values.classroomPublicId === "")}
        />
      ) : (
        <Input label="Nama Siswa" value={modal?.data?.studentName} disabled />
      )}
      <Input
        label="Tanggal Pelanggaran"
        name="activityDate"
        type="date"
        value={values?.activityDate}
        onChange={handleChange}
        error={Boolean(errors.activityDate && touched.activityDate)}
        errorText={
          Boolean(errors.activityDate && touched.activityDate) &&
          errors.activityDate
        }
      />
      <Select
        label="Pelanggaran"
        placeholder="Pilih Pelanggaran"
        name="violationPublicId"
        defaultValue={getPelanggaran?.data?.find(
          (item) => item.value === values?.violationPublicId
        )}
        onChange={(val) =>
          setValues({ ...values, violationPublicId: val.value })
        }
        options={getPelanggaran?.data ?? []}
        error={Boolean(errors?.violationPublicId && touched?.violationPublicId)}
        errorText={
          Boolean(errors?.violationPublicId && touched?.violationPublicId) &&
          errors?.violationPublicId
        }
        errorFetch={getPelanggaran.isError}
        errorFetchText={getPelanggaran?.error?.response?.data?.errorMessage[0]}
        loading={getPelanggaran.isFetching}
      />
      <TextArea
        label="Keterangan (opsional)"
        name="description"
        value={values.description}
        rows={4}
        onChange={handleChange}
        error={Boolean(errors.description && touched.description)}
        errorText={
          Boolean(errors.description && touched.description) &&
          errors.description
        }
      />
    </div>
  );
};

const DetailPelanggaran = ({ data, error }) => {
  return (
    <>
      <RowDetailLayout label="Nama Siswa" text={data?.studentName} />
      <RowDetailLayout
        label="NIS (Nomor Induk Siswa)"
        text={data?.studentNis}
      />
      <RowDetailLayout label="Kelas" text={data?.classroomName} />
      <RowDetailLayout
        label="Tanggal Pelanggaran"
        text={data?.activityDate ? getDashDMY(data?.activityDate) : "-"}
      />
      <RowDetailLayout label="Pelanggaran" text={data?.violationName} />
      <RowDetailLayout label="Keterangan" text={data?.description} />
      {error.error && <Label type="error" text={error.errorText} />}
    </>
  );
};

export const ContentPelanggaran = () => {
  const { auth } = useAuth();
  const permissions = auth.permissions;
  const axiosPrivate = useAxiosPrivate();

  // <--- States --->
  const [grade, setGrade] = useState({
    publicId: "",
    name: "",
  });
  const [classroom, setClassroom] = useState({
    publicId: "0",
    name: "Semua",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    isActive: true,
    startDate: getDashYMD(new Date()),
    classroomPublicId: undefined,
    gradePublicId: undefined,
  });
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });

  // <--- useQuery --->
  const getDetailGuru = useQuery(["detail-guru"], () =>
    TeachersApi.getDetailTeacher(axiosPrivate).then((res) => {
      setPagination({
        ...pagination,
        gradePublicId: res?.gradeGuide?.publicId,
      });
      setGrade({
        publicId: res?.gradeGuide?.publicId,
        name: res?.gradeGuide?.name,
      });
      getKelas.refetch();
    })
  );

  const getKelas = useQuery(
    ["pelanggaran-atribut-kelas", grade],
    () =>
      ClassroomsApi.getClassrooms(
        axiosPrivate,
        { gradePublicId: grade?.publicId },
        true
      ),
    {
      enabled: Boolean(
        !getDetailGuru.isFetching &&
          !getDetailGuru.isError &&
          grade.publicId !== ""
      ),
    }
  );

  const getPelanggaran = useQuery(["pelanggaran-siswa-list", pagination], () =>
    BerandaApi.getListPelanggaran(axiosPrivate, pagination).catch(() => {
      return [];
    })
  );

  // <--- Table's column --->
  const columns = [
    {
      name: "No",
      selector: (row, index) =>
        (getPelanggaran.data?.pageable?.pageNumber - 1) *
          getPelanggaran.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "NIS",
      selector: (row) => row?.studentNis,
      width: "100px",
      wrap: true,
    },
    {
      name: "Nama Siswa",
      selector: (row) => row?.studentName,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Kelas",
      selector: (row) => row?.classroomName,
      width: "80px",
      wrap: true,
    },
    {
      name: "Pelanggaran",
      selector: (row) => row?.violationName,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Keterangan",
      selector: (row) => row?.description,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Aksi",
      button: true,
      cell: (data) => (
        <>
          <div className="flex flex-row">
            {permissions.includes("VIOLATION_U") && (
              <EditButton
                icon
                noText
                onClick={() => onUpdateButtonClickHandler(data)}
                className="mr-1"
              />
            )}
            {permissions.includes("VIOLATION_D") && (
              <DeleteButon
                icon
                noText
                onClick={() => onDeleteButtonClickHandler(data)}
                className="mr-1"
              />
            )}
          </div>
        </>
      ),
      width: "100px",
    },
  ];

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onCreateButtonClickHandler = () =>
    setModal({
      show: true,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onUpdateButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "update",
      errorText: "",
      error: false,
      data: data,
    });

  const onDeleteButtonClickHandler = (data) =>
    setModal({
      show: true,
      type: "delete",
      errorText: "",
      error: false,
      data: data,
    });

  // <--- useMutation --->
  const createPelanggaranSiswa = useMutation(
    ({ data, studentPublicId }) =>
      BerandaApi.createPelanggaran(axiosPrivate, studentPublicId, data),
    {
      onSuccess: () => {
        getPelanggaran.refetch();
      },
    }
  );

  const updatePelanggaranSiswa = useMutation(
    ({ data, studentPublicId, studentHasViolationPublicId }) =>
      BerandaApi.updatePelanggaran(
        axiosPrivate,
        studentPublicId,
        studentHasViolationPublicId,
        data
      ),
    {
      onSuccess: () => {
        getPelanggaran.refetch();
      },
    }
  );

  const deletePelanggaranSiswa = useMutation(
    ({ studentPublicId, studentHasViolationPublicId }) =>
      BerandaApi.deletePelanggaran(
        axiosPrivate,
        studentPublicId,
        studentHasViolationPublicId
      ),
    {
      onSuccess: () => {
        getPelanggaran.refetch();
      },
    }
  );

  if (getDetailGuru.isFetching || getDetailGuru.isError) {
    return (
      <DataStatus
        loading={getDetailGuru.isLoading}
        loadingText="Memuat data..."
        text="Gagal memuat data guru"
      />
    );
  } else {
    return (
      <>
        <div className="sm:flex sm:flex-row">
          <div className="flex flex-row flex-1">
            <Input
              label="Tanggal"
              name="startDate"
              type="date"
              value={pagination.startDate}
              onChange={(e) =>
                setPagination({
                  ...pagination,
                  startDate: e.target.value,
                })
              }
              wrapperClassName="mr-3"
              withSelect={true}
            />
            <Select
              label="Kelas"
              placeholder="Pilih Kelas"
              defaultValue={getKelas?.data?.find(
                (item) => item.value === classroom?.publicId
              )}
              onChange={(val) => {
                setPagination({
                  ...pagination,
                  gradePublicId:
                    val.value !== "0" ? undefined : grade?.publicId,
                  classroomPublicId: val.value !== "0" ? val.value : undefined,
                });
                setClassroom({
                  ...classroom,
                  publicId: val.value,
                  name: val.label,
                });
              }}
              options={getKelas?.data ?? []}
              errorFetch={getKelas.isError}
              errorFetchText={getKelas?.error?.response?.data?.errorMessage[0]}
              loading={getKelas.isFetching}
              disable={Boolean(getDetailGuru.isLoading)}
            />
          </div>
          <div className="sm:grid sm:content-end mt-3 sm:mt-0 sm:w-auto w-full">
            <CreateButton
              icon
              type="button"
              onClick={onCreateButtonClickHandler}
              text="Tambah Pelanggaran"
              className="sm:w-auto w-full"
            />
          </div>
        </div>
        {/* <--- Table --->  */}
        <ListLayout
          permissions={permissions}
          data={getPelanggaran.data?.content}
          columns={columns}
          loading={Boolean(
            getDetailGuru.isFetching || getPelanggaran.isFetching
          )}
          error={getPelanggaran.error?.response?.data?.errorMessage[0]}
          pagination={true}
          totalRows={getPelanggaran.data?.pageable?.totalElements}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowsChange}
          customLeftTopSection={() => <></>}
        />

        {/* <--- MODAL CREATE ---> */}
        {modal.type === "create" && modal.show && (
          <Formik
            enableReinitialize
            initialValues={formPelanggaranInitialValues({})}
            validationSchema={formValidationPelanggaranSchema("create")}
            onSubmit={async (values, { resetForm }) => {
              createPelanggaranSiswa
                .mutateAsync({
                  data: values,
                  studentPublicId: values.studentPublicId,
                })
                .then((res) => {
                  resetForm();
                  successFetching(res);
                  onHideModalHandler();
                })
                .catch((err) => {
                  const errorMessage = errorFetching(err);
                  setModal({
                    ...modal,
                    error: true,
                    errorText: errorMessage,
                  });
                });
            }}
          >
            {(formik) => {
              const { handleSubmit, resetForm } = formik;

              return (
                <Modal
                  id="modal-tambah-pelanggaran-siswa"
                  header="Tambah Pelanggaran Siswa"
                  size="medium"
                  type="create"
                  onHide={() => {
                    resetForm();
                    onHideModalHandler();
                  }}
                  show={Boolean(modal.show && modal.type === "create")}
                  onSubmit={handleSubmit}
                  isSubmitting={createPelanggaranSiswa.isLoading}
                >
                  <FormPelanggaran modal={modal} grade={grade} />
                </Modal>
              );
            }}
          </Formik>
        )}

        {/* <--- MODAL UPDATE ---> */}
        {modal.type === "update" && modal.show && (
          <Formik
            enableReinitialize
            initialValues={formPelanggaranInitialValues(modal?.data)}
            validationSchema={formValidationPelanggaranSchema("update")}
            onSubmit={(values, { resetForm }) => {
              updatePelanggaranSiswa
                .mutateAsync({
                  data: values,
                  studentPublicId: values.studentPublicId,
                  studentHasViolationPublicId:
                    values.studentHasViolationPublicId,
                })
                .then((res) => {
                  resetForm();
                  successFetching(res);
                  onHideModalHandler();
                })
                .catch((err) => {
                  const errorMessage = errorFetching(err);
                  setModal({
                    ...modal,
                    error: true,
                    errorText: errorMessage,
                  });
                });
            }}
          >
            {(formik) => {
              const { handleSubmit } = formik;

              return (
                <Modal
                  id="modal-ubah-pelanggaran-siswa"
                  header="Ubah Pelanggaran Siswa"
                  size="medium"
                  type="update"
                  onHide={onHideModalHandler}
                  show={Boolean(modal.show && modal.type === "update")}
                  onSubmit={handleSubmit}
                  isSubmitting={updatePelanggaranSiswa.isLoading}
                >
                  <FormPelanggaran modal={modal} grade={grade} />
                </Modal>
              );
            }}
          </Formik>
        )}

        {/* <--- MODAL DELETE ---> */}
        {modal.type === "delete" && modal.show && (
          <Modal
            id="modal-hapus-pelanggaran-siswa"
            header="Hapus Pelanggaran Siswa"
            size="medium"
            type="delete"
            onHide={onHideModalHandler}
            show={Boolean(modal.show && modal.type === "delete")}
            isSubmitting={deletePelanggaranSiswa.isLoading}
            onSubmit={() => {
              deletePelanggaranSiswa
                .mutateAsync({
                  studentPublicId: modal?.data?.studentPublicId,
                  studentHasViolationPublicId:
                    modal?.data?.studentHasViolationPublicId,
                })
                .then((res) => {
                  successFetching(res);
                  onHideModalHandler();
                })
                .catch((err) => {
                  const errorMessage = errorFetching(err);
                  setModal({
                    ...modal,
                    error: true,
                    errorText: errorMessage,
                  });
                });
            }}
          >
            <DetailPelanggaran data={modal?.data} error={modal} />
          </Modal>
        )}
      </>
    );
  }
};
