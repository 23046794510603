import { RowDetailLayout, Tabs } from 'components'
import { getDashDMY } from 'utilities'
import { HeaderSection } from '.'

const TabInformasiUmum = ({ data }) => {
  return (
    <div className="space-y-5">
      <RowDetailLayout 
        label='NIP (Nomor Induk Pegawai)'
        text={data?.nip}
      />
      <RowDetailLayout 
        label='Tanggal Lahir'
        text={data?.dateOfBirth ? getDashDMY(data?.dateOfBirth) : '-'}
      />  
      <RowDetailLayout 
        label='Jenis Kelamin'
        text={data?.gender === 'P' ? 'Perempuan' : 'Laki-Laki'}
      />
      <RowDetailLayout 
        label='Email'
        text={data?.email}
      />
    </div>
  )
}

const TabAlamat = ({ data }) => {
  return (
    <div className="space-y-5">
      <RowDetailLayout
        label='Provinsi'
        text={data?.address?.desa?.kecamatan?.kabupaten?.provinsi?.nama}
      />
      <RowDetailLayout
        label='Kabupaten'
        text={data?.address?.desa?.kecamatan?.kabupaten?.nama}
      />
      <RowDetailLayout
        label='Kecamatan'
        text={data?.address?.desa?.kecamatan?.nama}
      />
      <RowDetailLayout
        label='Desa'
        text={data?.address?.desa?.nama}
      />
      <RowDetailLayout
        label='Alamat'
        text={data?.address?.street}
      />
    </div>
  )
}

export const DetailSection = ({ data, type, setType }) => {
  return (
    <>
      <HeaderSection data={data} type={type} setType={setType} />
      <hr />
      <Tabs 
        data={[
          {
            title: 'Informasi Umum',
            component: <TabInformasiUmum data={data} />
          },
          {
            title: 'Alamat',
            component: <TabAlamat data={data} />
          }
        ]}
      />
    </>
  )
}
