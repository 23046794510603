import { TeachersApi } from "api";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  errorFetching,
  successFetching,
  useAuth,
  useAxiosPrivate,
} from "utilities";
import VerifikasiAkunApi from "./__VerifikasiAkunApi__";
import {
  CreateButton,
  DataStatus,
  LabelDanger,
  LabelDefault,
  LabelSuccess,
  ListLayout,
  Modal,
} from "components";
import { Formik } from "formik";
import {
  formVerifikasiAkunInitialValues,
  formVerifikasiAkunValidationSchema,
} from "./__VerifikasiUtilities__";
import { FormVerifikasiAkun } from "./__VerifikasiAkunComponents__";

export const VerifikasiAkun = () => {
  const { auth } = useAuth();
  const permissions = auth.permissions;
  const axiosPrivate = useAxiosPrivate();

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    kelasPublicId: undefined,
  });
  const [modal, setModal] = useState({
    show: false,
    type: "create",
    errorText: "",
    error: false,
    data: {},
  });

  // <--- useQuery --->
  const getDetailGuru = useQuery(
    ["detail-guru", "verifikasi-akun", "orang-tua"],
    () =>
      TeachersApi.getDetailTeacher(axiosPrivate).then((res) => {
        setPagination({
          ...pagination,
          kelasPublicId: res?.classroomGuide?.publicId,
        });
      })
  );

  const getList = useQuery(
    ["list-akun", "orang-tua", pagination],
    () => VerifikasiAkunApi.getList(axiosPrivate, pagination),
    {
      enabled: Boolean(pagination?.kelasPublicId),
    }
  );

  // <--- Table's column --->
  const columns = [
    {
      name: "No",
      selector: (row, index) =>
        (getList.data?.pageable?.pageNumber - 1) *
          getList.data?.pageable?.pageSize +
        index +
        1,
      width: "70px",
    },
    {
      name: "Nama Orang Tua",
      selector: (row) => row?.orangTuaNama,
      minWidth: "300px",
      wrap: true,
    },
    {
      name: "No. Handphone",
      selector: (row) => row?.orangTuaNoHp,
      wrap: true,
      minWidth: "100px",
    },
    {
      name: "Kategori",
      selector: (row) => row?.orangTuaKategoriNama,
      minWidth: "120px",
      wrap: true,
    },
    {
      name: "Nama Anak",
      selector: (row) => row?.siswaNama,
      wrap: true,
      minWidth: "300px",
    },
    {
      name: "Status",
      selector: (row) =>
        row?.status === "PENDING" ? (
          <LabelDefault text="PENDING" />
        ) : row?.status === "APPROVED" ? (
          <LabelSuccess text="APPROVED" />
        ) : row?.status === "REJECTED" ? (
          <LabelDanger text="REJECTED" />
        ) : (
          "-"
        ),
      width: "100px",
    },
    {
      name: "Aksi",
      button: true,
      cell: (data) => (
        <>
          <div className="flex flex-row">
            {permissions.includes("SCH_OTS_V") && data?.status === "PENDING" ? (
              <CreateButton
                text="Verifikasi"
                onClick={() => onClickVerifikasiButton(data)}
              />
            ) : (
              "-"
            )}
          </div>
        </>
      ),
      width: "100px",
    },
  ];

  // <--- Functions --->
  const handlePageChange = (page) =>
    setPagination({ ...pagination, page: page });

  const handlePerRowsChange = (dataLength) =>
    setPagination({ ...pagination, size: dataLength });

  const searchDataHandler = (e) => {
    let time;

    clearTimeout(time);

    time = setTimeout(() => {
      setPagination({
        ...pagination,
        search: e.target.value,
        searchBy: "all",
      });
    }, 1000);
  };

  const onHideModalHandler = () =>
    setModal({
      show: false,
      type: "create",
      errorText: "",
      error: false,
      data: {},
    });

  const onClickVerifikasiButton = (data) =>
    setModal({
      show: true,
      type: "verifikasi",
      errorText: "",
      error: false,
      data: data,
    });

  const verifikasiAkun = useMutation(
    ({ orangTuaPublicId, enumStatus, keterangan }) =>
      VerifikasiAkunApi.verifikasi(axiosPrivate, orangTuaPublicId, {
        enumStatus: enumStatus,
        keterangan: enumStatus === "REJECTED" ? keterangan : undefined,
      }),
    {
      onSuccess: () => {
        getList.remove();
        getList.refetch();
      },
    }
  );

  return (
    <>
      {getDetailGuru.isFetching || getDetailGuru.isError ? (
        <DataStatus
          loading={getDetailGuru.isFetching}
          loadingText="Memuat data..."
          text="Gagal memuat data guru"
        />
      ) : (
        <>
          <ListLayout
            permissions={permissions}
            data={getList.data?.content}
            columns={columns}
            loading={Boolean(getDetailGuru.isFetching || getList.isFetching)}
            error={getList.error?.response?.data?.errorMessage[0]}
            pagination={true}
            totalRows={getList.data?.pageable?.totalElements}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            onSearchChange={searchDataHandler}
          />

          {/* <--- MODAL VERIFIKASI AKUN ---> */}
          {modal.type === "verifikasi" && modal.show && (
            <Formik
              enableReinitialize
              initialValues={formVerifikasiAkunInitialValues(modal?.data)}
              validationSchema={formVerifikasiAkunValidationSchema()}
              onSubmit={(values, { resetForm }) => {
                verifikasiAkun
                  .mutateAsync({
                    orangTuaPublicId: modal.data?.publicId,
                    enumStatus: values?.enumStatus,
                    keterangan: values?.keterangan,
                  })
                  .then((res) => {
                    resetForm();
                    successFetching(res);
                    onHideModalHandler();
                  })
                  .catch((err) => {
                    const errorMessage = errorFetching(err);
                    setModal({
                      ...modal,
                      error: true,
                      errorText: errorMessage,
                    });
                  });
              }}
            >
              {(formik) => {
                const { handleSubmit, resetForm } = formik;
                return (
                  <Modal
                    id="modal-verifikasi-akun-orang-tua"
                    header="Verifikasi Akun Orang Tua"
                    size="small"
                    type="create"
                    onHide={() => {
                      resetForm();
                      onHideModalHandler();
                    }}
                    show={Boolean(modal.show && modal.type === "verifikasi")}
                    isSubmitting={verifikasiAkun.isLoading}
                    onSubmit={handleSubmit}
                  >
                    <FormVerifikasiAkun />
                  </Modal>
                );
              }}
            </Formik>
          )}
        </>
      )}
    </>
  );
};
