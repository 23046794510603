import { Navigate, Route, Routes } from 'react-router-dom';
import { BaseLayout } from "components";
import Config from '../config';
import { useAuth, useRefreshToken } from '../utilities';
import { LoadingPage, Login, PageNotFound } from '../views';

export const MainRoutes = () => {
    const { ROUTES } = Config;
    const { auth } = useAuth();
    const refresh = useRefreshToken();

    // Authorization's routes
    const AuthRoutes = () => {
        return (
            <Routes>
                {/* ROUTES CONFIG */}
                {ROUTES.map((value, index) => <Route key={index} path={value.path} element={<BaseLayout title={value.name}>{value.page}</BaseLayout>} />)}

                {/* LOGIN FORM */}
                <Route path='/login' element={<Navigate to={localStorage?.getItem('siswaku-guru-link') ?? '/'} replace />} />
            
                {/* PAGE NOT FOUND */}
                <Route path='*' element={<BaseLayout title="Halaman Tidak Ditemukan"><PageNotFound /></BaseLayout>} />
            </Routes>
        )
    }

    // UnAuthorization's routes
    const UnAuthRoute = () => {
        return (
            <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='*' element={<Navigate to="/login" replace />} />
            </Routes>
        )
    }

    // All routes for MainRoutes' return
    const AllRoutes = () => {
        if(auth?.status === 'unauthenticated'){
            return <UnAuthRoute />
        } else {
            if(auth?.status === undefined){
                refresh()
                return <LoadingPage />
            } else if(auth?.status === 'unauthenticated'){
                return <UnAuthRoute />
            } else{
                return <AuthRoutes />
            }
        }
    }

    return (
        <AllRoutes />
    )
}