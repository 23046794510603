export const ConvertFlagPresensiHarian = ({ flag }) => {
  switch (flag) {
    case "MASUK":
      return <div className="text-green-500">M</div>;
    case "TERLAMBAT":
      return <div className="text-red-500">T</div>;
    case "IZIN_SAKIT":
      return <div className="bg-pink-200 text-center font-bold">S</div>;
    case "IZIN":
      return <div className="bg-amber-200 text-center font-bold">I</div>;
    case "MASUK_KELUAR":
      return <div className="bg-gray-200 text-center font-bold">IM</div>;
    case "IZIN_TERLAMBAT":
      return <div className="bg-blue-200 text-center font-bold">IT</div>;
    default:
      return <div>-</div>;
  }
};
