const url = window._env_.REACT_APP_API_STUDENTSHIP_URL

class BerandaApi {
  async getListPelanggaran(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/violations/pageable?direction=DESC`, { params })
    return fetch.data.payload
  }

  createPelanggaran(axiosPrivate, studentPublicId, data) {
    return axiosPrivate.post(`${url}/v1/students/${studentPublicId}/violations`, data)
  }

  updatePelanggaran(axiosPrivate, studentPublicId, studentHasViolationPublicId, data) {
    return axiosPrivate.put(`${url}/v1/students/${studentPublicId}/violations/${studentHasViolationPublicId}`, data)
  }

  deletePelanggaran(axiosPrivate, studentPublicId, studentHasViolationPublicId) {
    return axiosPrivate.delete(`${url}/v1/students/${studentPublicId}/violations/${studentHasViolationPublicId}`)
  }

  async getListPrestasi(axiosPrivate, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/achievements/pageable?direction=DESC`, { params })
    return fetch.data.payload
  }

  async getDetailPrestasiSiswa(axiosPrivate, studentPublicId, studentHasAchievementPublicId) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/${studentPublicId}/achievements/${studentHasAchievementPublicId}`)
    return fetch.data.payload
  }

  async getAchievementLogsPrestasiSiswa(axiosPrivate, studentPublicId, studentHasAchievementPublicId, params) {
    const fetch = await axiosPrivate.get(`${url}/v1/students/${studentPublicId}/achievements/${studentHasAchievementPublicId}/logs/pageable`, { params })
    return fetch.data.payload
  }

  updateStatusPrestasi(axiosPrivate, studentPublicId, studentHasAchievementPublicId, data) {
    return axiosPrivate.patch(`${url}/v1/students/${studentPublicId}/achievements/${studentHasAchievementPublicId}/status`, data)
  }
}

export default new BerandaApi()