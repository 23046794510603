
export const Card = ({
  title = '',
  titleComponent,
  body,
  wrapperClassname = ''
}) => {
  return (
    <div className={`overflow-hidden bg-white border-clobasoft-light-gray border-[1px] rounded-md shadow-md ${wrapperClassname}`}>
      <div className="px-4 py-4">
        {title !== '' ? <>
          <label className="text-lg font-semibold text-clobasoft-dark-gray">{title}</label>  
        </> : titleComponent}
      </div>
      <div className="border-t border-gray-200">
        <div className="px-4 py-4">
          {body}
        </div>
      </div>
    </div>
  )
}
