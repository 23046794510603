import { TeachersApi } from "api"
import { DataStatus, Input, ListLayout } from "components"
import { useState } from "react"
import { useQuery } from "react-query"
import { getDashYMD, useAxiosPrivate } from "utilities"
import BerandaApi from "views/Beranda/__BerandaApi__"

export const Pelanggaran = () => {
  const axiosPrivate = useAxiosPrivate()

  // <--- States --->
  const [pagination, setPagination] = useState({
    page: 1,
    size: 10,
    isActive: true,
    startDate: getDashYMD(new Date()),
    classroomPublicId: undefined
  })

  // <--- useQuery --->
  const getDetailGuru = useQuery(
    ['detail-guru'], 
    () => TeachersApi.getDetailTeacher(axiosPrivate).then(res => {
      setPagination({
        ...pagination,
        classroomPublicId: res?.classroomGuide?.publicId
      })
    })
  )

  const getPelanggaran = useQuery(
    ['pelanggaran-siswa-list', pagination],
    () => BerandaApi.getListPelanggaran(axiosPrivate, pagination).catch(() => { return [] })
  )

  // <--- Table's column --->
  const columns = [
    {
      name: 'No',
      selector: (row, index) => ((getPelanggaran.data?.pageable?.pageNumber - 1) * getPelanggaran.data?.pageable?.pageSize) + index + 1,
      width: "70px" 
    },
    {
      name: 'NIS',
      selector: row => row?.studentNis,
      width: '100px',
      wrap: true
    },
    {
      name: 'Nama Siswa',
      selector: row => row?.studentName,
      wrap: true,
      minWidth: '300px'
    },
    {
      name: 'Kelas',
      selector: row => row?.classroomName,
      width: '80px',
      wrap: true
    },
    {
      name: 'Pelanggaran',
      selector: row => row?.violationName,
      wrap: true,
      minWidth: '300px'
    },
    {
      name: 'Keterangan',
      selector: row => row?.description,
      wrap: true,
      minWidth: '300px'
    }
  ]

  // <--- Functions --->
  const handlePageChange = page => setPagination({...pagination, page: page})

  const handlePerRowsChange = dataLength => setPagination({ ...pagination, size: dataLength })

  if(getDetailGuru.isFetching || getDetailGuru.isError){
    return (
      <DataStatus 
        loading={getDetailGuru.isLoading} 
        loadingText='Memuat data...' 
        text='Gagal memuat data guru'
      /> 
    )
  } else{
    return (
      <>
        <Input 
          label='Tanggal'
          name='startDate'
          type='date'
          value={pagination.startDate}
          onChange={(e) => setPagination({
            ...pagination,
            startDate: e.target.value
          })}
          wrapperClassName='mr-1 sm:w-1/6'
        />
        {/* <--- Table --->  */}
        <ListLayout 
          data={getPelanggaran.data?.content}
          columns={columns}
          loading={Boolean(getDetailGuru.isFetching || getPelanggaran.isFetching)}
          error={getPelanggaran.error?.response?.data?.errorMessage[0]}
          pagination={true}
          totalRows={getPelanggaran.data?.pageable?.totalElements}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowsChange}
          customLeftTopSection={() => <></>}
        />
      </>
    )
  }
}
